import * as amplitude from '@amplitude/analytics-browser';
import { amplitudeKey } from './replicant/config';
import { useStore } from './data/store/useStore';
import { OGPWidgetWrapper } from './widget/widget';
import { Test } from './widget/test';
import { useEffect } from 'react';

amplitude.init(amplitudeKey);

function Application() {

  const { state, updateState } = useStore();

  const onClick = () => {
    updateState({
      ui: {
        showBtn: !state.ui.showBtn,
      }
    })
  }

  const onColor = () => {
    const genRanHex = [...Array(6)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
    updateState({
      ui: {
        theme: { 
          color: `#${genRanHex}`,
        }
      }
    })
  }

  const onBtnColor = () => {
    const genRanHex = [...Array(6)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
    updateState({
      ui: {
        color: `#${genRanHex}`,
      }
    })
  }

  useEffect(() => {
    console.log('Cai is here');
    // @ts-ignore
    // ogpWidget.init('well-done-cai');
  }, []);

  return (
    <Test />
  )

  // return (
  //   <OGPWidgetWrapper />
  // )

  return (
    <div style={{background: state.ui.theme.color}}>
      Hello Open World
      {
        state.ui.showBtn && (
          <p style={{color: state.ui.color}}>button is ON</p>
        )
      }
      <button onClick={onClick}>Click me</button>
      <button onClick={onColor}>Change color</button>
      <button onClick={onBtnColor}>Change btn color</button>
    </div>
  );
}

export default Application;