import { useEffect, useRef } from "react"

const iframeStyle = {
  position: 'absolute',
  width: 400,
  height: 400,
}

let hasRunOnce = false;
export const Test = () => {

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const onLogin = () => {
    if (iframeRef?.current?.contentWindow) {
      iframeRef?.current?.contentWindow.postMessage({action: 'login', data: {
        points: 1000, 
      }}, "*");
    }
  }

  const onLogout = () => {
    if (iframeRef?.current?.contentWindow) {
      iframeRef?.current?.contentWindow.postMessage({action: 'logout', data: {
        points: 1000, 
      }}, "*");
    }
  }

  useEffect(() => {
    if (iframeRef.current?.contentWindow) {
      if (!hasRunOnce) {
        hasRunOnce = true;
      }
      console.log('HERE!!!');
      setTimeout(() => {
        console.error('Should call init');
        iframeRef.current!.contentWindow!.postMessage({action: 'init', data: {
          gameId: 'cai-test', 
        }}, "*");
      }, 10000);
      
    }
  }, [iframeRef]);

  useEffect(() => {
    // @ts-ignore
    OpenGameSDK.init({
      apiKey: '9f28591a-0472-4a2c-8221-f43469291ab8',
      playerId: 'cai-tester-1',
    }).then(() => {
      console.log('OpenGameSDK has been initialised.');
    }).catch(() => {
      console.error('OpenGameSDK has failed to initialise.');
    })
  }, []);

  const savePoints = () => {
    // @ts-ignore
    OpenGameSDK.savePoints(1000);
  }
  
  const getPoints = () => {
    // @ts-ignore
    OpenGameSDK.getPoints().then(console.log);
  }

  const listUserRewards = () => {
    // @ts-ignore
    OpenGameSDK.listUserRewards().then(console.log);
  }

  const listGameRewards = () => {
    // @ts-ignore
    OpenGameSDK.listGameRewards().then(console.log);
  }

  const getTimeUntilNextClaim = () => {
    // @ts-ignore
    OpenGameSDK.getTimeUntilNextClaim().then(console.log);
  }

  const fns: any = {
    savePoints,
    getPoints,
    listUserRewards,
    listGameRewards,
    getTimeUntilNextClaim
  }


  return (
    <div>
      {
        Object.keys(fns).map(fnName => (
          <>
            <button onClick={fns[fnName]}>{fnName}</button>
            <br />
            <br />
          </>
        ))
      }
      {/* <br />
      <button onClick={onLogin}>Login</button>
      <br />
      <button onClick={onLogout}>Logout</button>
      <br />
      <iframe style={iframeStyle as any} ref={iframeRef}  src="https://notgemz.alpha.pnk.one/" sandbox="allow-scripts allow-same-origin"></iframe> */}
    </div>
  )
}